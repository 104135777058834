import { DocumentIcon } from "@heroicons/react/24/outline";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ButtonBase, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { Box, styled as matStyled, MUIStyledCommonProps } from "@mui/system";
import Lottie from "react-lottie-player";
import Color from "../../../../assets/colors";

type ResumeStatus = "rejected" | "in_review" | "approved";
type ResumeButtonVariant = "normal" | "filled";

interface ResumeStatusChipProps extends MUIStyledCommonProps<Theme> {
  status: ResumeStatus;
  children: React.ReactNode;
}

interface ResumeButtonProps extends MUIStyledCommonProps<Theme> {
  variant: ResumeButtonVariant;
  children: React.ReactNode;
}

// Title
export const UploadResumeTitle = matStyled(Typography)(({ theme }) => ({
  marginTop: "26px",
  fontFamily: "Inter",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "25px",
  lineHeight: "32px",

  [theme.breakpoints.between("xs", 768)]: {
    fontSize: "20px",
    lineHeight: "24px",
  },
}));

export const UploadResumeSubTitle = matStyled(Typography)(({ theme }) => ({
  marginTop: "10px",
  fontFamily: "Inter",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "22px",

  [theme.breakpoints.between("xs", 768)]: {
    fontSize: "12px",
    lineHeight: "16.8px",
  },
}));

export const UploadResumeContainer = matStyled("form")((props: any) => ({
  position: "relative",
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  minHeight: props.visitor ? "184px" : "341px",
  background: Color.lightGrey,
  border: `1px dashed ${Color.darkGrey}`,
  borderRadius: "20px",
  minWidth: "360px",

  [props.theme.breakpoints.down(768)]: {
    minWidth: "328px",
  },
}));

export const InputStyled = matStyled("input")({
  display: "none",
});

export const DragResumeElement = matStyled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
  zIndex: 2,
});

export const LottieStyled = matStyled(Lottie)((props: any) => ({
  width: "157.419px",
  height: "120px",

  [props.theme.breakpoints.down(768)]: {
    height: "93px",
    width: "122px",
  },
}));

export const ImportButton = matStyled(Button)((props) => ({
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  borderRadius: "100px",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "19px",
  textTransform: "none",

  height: "36px",
  width: "160px",
  paddingInline: "1.5rem",

  "&:hover": {
    backgroundColor: Color.hiredlyPurple,
  },

  [props.theme.breakpoints.down(768)]: {
    width: "100%",
  },
}));

export const ResumeButton = matStyled(ButtonBase)<ResumeButtonProps>(
  (props) => ({
    color: Color.black,
    backgroundColor: Color.white,
    border: `1px solid ${Color.black}`,
    borderRadius: "6.25rem",
    minWidth: "112px",

    fontSize: "0.75rem",
    fontWeight: "400",
    whiteSpace: "nowrap",

    height: "30px",
    padding: "0 1rem",

    ...(props.variant === "filled" && {
      backgroundColor: Color.black,
      color: Color.white,

      fontWeight: "700",
    }),

    "@media (max-width: 768px)": {
      width: "100%",
      ...(props.variant === "normal" && {
        padding: "0 1.5rem",
      }),
    },
  })
);

export const OrContainer = matStyled("div")({
  marginTop: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

export const Dash = matStyled("div")({
  width: "221px",
  height: "1px",
  background: Color.secondaryGrey,
});

export const OrText = matStyled(Typography)({
  padding: "0 10px",
  fontFamily: "Inter",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "20px",
  color: "rgba(0, 0, 0, 0.6)",
});

export const DropResumeContainer = matStyled("div")({
  marginTop: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

export const SignUpResumeWrapper = matStyled("div")((props) => ({
  display: "flex",
  paddingBlock: "0.875rem",
  paddingInline: "1rem",
  borderRadius: "10px",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: Color.lightGrey,

  [props.theme.breakpoints.down(768)]: {
    padding: "0.625rem 0.75rem",
    flexDirection: "column",
    gap: "0.75rem",
  },
}));

export const ResumeStatusChip = matStyled("div")<ResumeStatusChipProps>(
  (props) => ({
    borderRadius: "6.25rem",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    fontSize: "0.625rem",
    fontWeight: "700",
    whiteSpace: "nowrap",

    height: "21px",
    padding: "0 0.625rem",

    ...(props.status === "rejected" && {
      color: Color.reject,
      backgroundColor: "rgba(190, 66, 66, 0.1)",
    }),

    ...(props.status === "in_review" && {
      color: "rgba(192, 132, 12, 1)",
      backgroundColor: "rgba(242, 175, 41, 0.14)",
    }),

    ...(props.status === "approved" && {
      color: "rgba(10, 132, 12, 1)",
      backgroundColor: "rgba(10, 132, 12, 0.10)",
    }),
  })
);

export const BoxContainer = matStyled(Box)({
  display: "flex",
});

export const ButtonContainer = matStyled(Box)({
  display: "flex",
  gap: "0.25rem",

  "@media (max-width: 768px)": {
    gap: "0.5rem",
    width: "100%",
  },
});

export const DropText = matStyled(Typography)({
  display: "flex",
  fontFamily: "Inter",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "16px",
  lineHeight: "22px",
  color: "rgba(0, 0, 0, 0.87)",
  letterSpacing: "0.5px",
});

export const DropSubText = matStyled(Typography)({
  marginLeft: "4px",
  fontFamily: "Inter",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "16px",
  lineHeight: "22px",
  color: "rgba(0, 0, 0, 0.87)",
});

export const DropTextPurple = matStyled(Typography)({
  marginLeft: "4px",
  fontFamily: "Inter",
  fontWeight: 700,
  fontStyle: "normal",
  textDecoration: "underline",
  fontSize: "16px",
  lineHeight: "22px",
  color: Color.hiredlyPurple,
  cursor: "pointer",
});

export const SupportFileText = matStyled(Typography)((props) => ({
  marginTop: "12px",
  fontFamily: "Inter",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "0.75rem",
  lineHeight: "20px",
  color: "rgba(0, 0, 0, 0.6)",
}));

export const EmptyContainer = matStyled(Grid)({});

export const UploadFileOutlinedIconStyled = matStyled(DocumentIcon)({
  width: "24px",
  height: "24px",
});

export const CloseIconStyled = matStyled(CloseIcon)({
  fontSize: "30px",
  color: "#BE4242",
});

export const ErrorOutlineIconStyled = matStyled(ErrorOutlineIcon)({
  fontSize: "20px",
  color: "#BE4242",
});

export const LinearProgressStyled = matStyled(LinearProgress)({
  borderRadius: "10px",
});

export const FilenameText = matStyled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
});

export const PercentageText = matStyled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  color: Color.hiredlyPurple,
});

export const DoneOutlinedIconStyled = matStyled(DoneOutlinedIcon)({
  color: "#90B938",
  fontSize: "30px",
});

export const OuterContainer = matStyled(Grid)((props) => ({
  position: "relative",
  display: "flex",
  width: "100%",
  background: Color.lightGrey,
  border: `1px dashed ${Color.darkGrey}`,
  borderRadius: "20px",
  minWidth: "360px",
  padding: "24px 30px",

  [props.theme.breakpoints.down(768)]: {
    minWidth: "256px",
    minHeight: "auto",
    flexDirection: "column",
    padding: "1rem 0",
  },
}));

export const UploadedResumeText = matStyled(Typography)((props) => ({
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.112px",
  overflow: "hidden",
  textOverflow: "ellipsis",

  [props.theme.breakpoints.down(768)]: {
    maxWidth: "197px",
  },
}));

export const InvalidResumeText = matStyled(Typography)({
  color: "#BE4242",
  fontStyle: "italic",
  fontSize: "12px",
});

export const SnackbarResumeCriteriaText = matStyled(Typography)({
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: "20px",
  fontStyle: "normal",
  color: Color.textMediumEmphasis,
});

export const ErrorContainer = matStyled(Grid)({
  gap: "4px",
  padding: "4px 6px",
  flexWrap: "nowrap",
  backgroundColor: "#BE42421A",
  border: "1px solid #BE4242",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

export const ResumeRejectionList = matStyled("ul")({
  margin: "0",
  paddingInlineStart: "20px",
});

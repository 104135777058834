import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Dialog from "@mui/material/Dialog";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../../assets/colors";

export const DialogContainer = styling(Dialog)((props) => ({
  "& .MuiDialog-container .MuiPaper-root": {
    border: "1.25px solid " + Color.black,
    borderRadius: "1.5rem",
    overflow: "clip",

    maxHeight: "600px",
    maxWidth: "1000px",
    height: "100%",
    width: "100%",

    ...(props.resumePreview && {
      maxHeight: "610px",
      maxWidth: "600px",
    }),

    "@media (max-width: 1279px)": {
      maxHeight: "600px",
      maxWidth: "700px",
    },

    "@media (max-width: 640px)": {
      maxHeight: "600px",
      maxWidth: "328px",
    },
  },
}));

export const DialogHeader = styling("div")({
  borderBottom: "1px solid" + Color.grey,
  padding: "1rem 1.5rem",

  "@media (max-width: 640px)": {
    padding: "0.75rem 0.75rem 0.5rem",
  },
});

export const DialogContent = styling("div")((props) => ({
  display: "grid",
  gridTemplateColumns: props.fullWidth ? "1fr" : "1fr 1fr",
  gap: "1.5rem",

  height: "100%",
  width: "100%",
  padding: "1rem 1.5rem",
  // paddingInline: "1rem",
  // paddingBlock: !props.fromSignUp && "1.5rem",

  "@media (max-width: 1279px)": {
    gap: "1rem",
  },

  "@media (max-width: 640px)": {
    gridTemplateColumns: "1fr",
    gap: "0",
    padding: "0",
    overflowY: "scroll",
  },
}));

export const DialogFooter = styling("div")({
  borderTop: "1px solid" + Color.grey,

  display: "flex",
  justifyContent: "center",

  padding: "1rem 1.875rem",
});

export const ReasonContainer = styling("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",

  width: "100%",
  paddingTop: !props.fromSignUp && "1.5rem",

  "@media (max-width: 1279px)": {
    padding: "0",
  },

  "@media (max-width: 640px)": {
    padding: "0.5rem 1rem 1rem",
    gap: "0.75rem",
  },
}));

export const ResumeContainer = styling("div")({
  height: "100%",
  width: "100%",
});

export const BoxContainer = styling(Box)((props) => ({
  display: "flex",

  [props.theme.breakpoints.down("sm")]: {
    gap: props.fromSignUp ? "0" : "1rem",
  },
}));

export const ReasonListContainer = styling("ol")((props) => ({
  background: Color.lightPurple,
  borderRadius: "0.625rem",

  width: "100%",
  padding: "1rem 0.75rem 1rem 2rem",
  margin: "0",

  "@media (max-width: 640px)": {
    padding: "0.5rem 0.5rem 0.5rem 1.5rem",
  },
}));

export const ReasonItem = styling("li")({
  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const HeaderText = styling(Typography)((props) => ({
  fontSize: "1.25rem",
  fontWeight: "700",
  lineHeight: "24px",

  "@media (max-width: 640px)": {
    fontSize: "1rem",
    letterSpacing: "0.1px",
  },
}));

export const SubHeaderText = styling(Typography)({
  color: "rgba(0, 0, 0, 0.60)",

  fontSize: "0.875rem",
  fontStyle: "italic",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    fontStyle: "italic",
    lineHeight: "20px",
    whiteSpace: "nowrap",
  },
});

export const ReasonTitleText = styling(Typography)({
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    lineHeight: "20px",
    letterSpacing: "0.112px",
  },
});

export const IssueText = styling(Typography)({
  color: "rgba(0, 0, 0, 0.60)",

  fontSize: "0.75rem",
  fontStyle: "italic",
  lineHeight: "20px",
  textAlign: "left",

  width: "100%",
});

export const LinkStyled = styling("span")((props) => ({
  color: Color.hiredlyPurple,
  textDecoration: "underline",

  "&:hover": {
    cursor: "pointer",
  },
}));

export const CloseButton = styling(ButtonBase)({});

export const ButtonStyled = styling(ButtonBase)((props) => ({
  color: Color.hiredlyPurple,
  fontWeight: "700",

  borderRadius: "6.25rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: "700",
  letterSpacing: "0.074px",

  height: "42px",
  padding: "0 2rem",

  transition: "all 0.15s ease-in-out",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    letterSpacing: "0.055px",
    height: "30px",
    width: "fit-content",
    padding: "0rem 1rem",
  },

  ...(props.filled && {
    color: Color.white,
    backgroundColor: Color.black,
    border: "1px solid" + Color.black,
  }),
}));

export const InputStyled = styling("input")({
  display: "none",
});

export const IframeStyled = styling("iframe")(() => ({
  background: "rgba(82, 86, 89, 1)",
  height: "100%",
  width: "100%",
  padding: "1.5rem",
}));

export const DangerIcon = styling(ExclamationTriangleIcon)({
  color: Color.reject,
  height: "24px",
  width: "24px",

  "@media (max-width: 640px)": {
    height: "18px",
    width: "18px",
  },
});

export const CloseIcon = styling(XMarkIcon)({
  color: Color.black,
  height: "24px",
  width: "24px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },
});

import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useMediaQuery } from "@mui/material";
import { Fragment } from "react";
import { Country, isPossiblePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../../assets/colors";
import * as constants from "../../../../helpers/constant";
import { validateEmail, validateName } from "../../../../helpers/regex";
import { store } from "../../../../redux/stores/store";
import {
  AutocompleteStyled,
  ErrorText,
  PhoneInputStyled,
  TextFieldStyled,
} from "./styles";
import { SignUpFormTextFieldProps } from "./types";

const FormTextField = (props: SignUpFormTextFieldProps) => {
  const {
    name,
    exist,
    userParams,
    setUserParams,
    isEmptyNameField,
    setIsEmptyNameField,
    isEmptyPhoneField,
    setIsEmptyPhoneField,
    isEmptyNationalityField,
    setIsEmptyNationalityField,
  } = props;

  const currentLocationHandler = (event, value) => {
    const isValid = value ? true : false;
    setUserParams({
      ...userParams,
    });
  };

  const mobileView = useMediaQuery("(max-width: 768px)");

  return (
    <Fragment>
      {name === "Mobile Number" ? (
        <PhoneNumberTextField
          name={name}
          userParams={userParams}
          setUserParams={setUserParams}
          exist={exist}
          isEmptyPhoneField={isEmptyPhoneField}
          setIsEmptyPhoneField={setIsEmptyPhoneField}
        />
      ) : name === "Nationality" ? (
        <SelectTextField
          name={name}
          userParams={userParams}
          setUserParams={setUserParams}
          exist={exist}
          isEmptyNationalityField={isEmptyNationalityField}
          setIsEmptyNationalityField={setIsEmptyNationalityField}
          mobileView={mobileView}
        />
      ) : (
        <NormalTextField
          name={name}
          userParams={userParams}
          setUserParams={setUserParams}
          exist={exist}
          isEmptyNameField={isEmptyNameField}
          setIsEmptyNameField={setIsEmptyNameField}
          mobileView={mobileView}
        />
      )}
    </Fragment>
  );
};

const NormalTextField = (props: SignUpFormTextFieldProps) => {
  const {
    name,
    exist,
    userParams,
    setUserParams,
    isEmptyNameField,
    setIsEmptyNameField,
    mobileView,
  } = props;
  const generatedPassword = Math.random().toString(36).slice(-10);

  const inputHandler = (e: any) => {
    const isValid = validateName(e.target.value);

    if (name === "Name") {
      if (e.target.value !== "") {
        setIsEmptyNameField(false);
      }

      setUserParams((prev: any) => ({
        ...prev,
        name: e.target.value,
        validName: isValid,
        password: generatedPassword,
      }));
    } else {
      const isValid = validateEmail(e.target.value);
      setUserParams((prev: any) => ({
        ...prev,
        email: e.target.value,
        validEmail: isValid,
        password: generatedPassword,
      }));
    }
  };

  if (name === "Name") {
    return (
      <TextFieldStyled
        label="Name*"
        // InputLabelProps={{ shrink: true }}
        error={isEmptyNameField || !userParams?.validName}
        // inputProps={{ style: { height: "48px" } }}
        helperText={
          isEmptyNameField
            ? "Required Field"
            : !userParams?.validName && "Please enter a valid name"
        }
        value={userParams?.name}
        onChange={inputHandler}
        // size={mobileView ? "small" : "medium"}
        // inputProps={{ style: { padding: "0.5rem 1rem" } }}
        // size="small"
        // inputProps={{ style: { padding: "0.5rem 1rem", height: "32px" } }}
        size="small"
        // sx={{ height: "48px" }}
        // InputLabelProps={{ style: { height: "48px" } }}
      />
    );
  } else if (
    name === "Email" &&
    "validEmail" in userParams &&
    "email" in userParams
  ) {
    return (
      <TextFieldStyled
        label="Email"
        error={!userParams?.validEmail || exist}
        helperText={
          exist
            ? "Account already exist, please login"
            : !userParams?.validEmail
            ? "Please enter a valid email"
            : ""
        }
        value={userParams?.email}
        onChange={inputHandler}
      />
    );
  }
};

const PhoneNumberTextField = (props: SignUpFormTextFieldProps) => {
  const {
    name,
    userParams,
    setUserParams,
    isEmptyPhoneField,
    setIsEmptyPhoneField,
  } = props;

  const inputHandler = (e: any) => {
    let mobileNumberValidatedString = String(e);
    const isValid = isPossiblePhoneNumber(mobileNumberValidatedString);

    if (e !== "") {
      setIsEmptyPhoneField(false);
    }

    setUserParams((prev: any) => ({
      ...prev,
      phone: e,
      validPhone: isValid,
    }));
  };

  const geoLocationStr = ((typeof process.env.NEXT_PUBLIC_JSW_GEOLOCATION ===
    "string" &&
    process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase()) ??
    "MY") as Country;

  return (
    <Fragment>
      <PhoneInputStyled
        defaultCountry={geoLocationStr}
        onChange={inputHandler}
        label={name}
        placeholder={name}
        countryCallingCodeEditable={false}
        international
        valid={!isEmptyPhoneField && userParams?.validPhone}
      />
      {isEmptyPhoneField || !userParams?.validPhone ? (
        <ErrorText>
          {isEmptyPhoneField
            ? "Required Field"
            : !userParams?.validPhone && "Please enter a valid phone number"}
        </ErrorText>
      ) : null}
    </Fragment>
  );
};

const SelectTextField = (props: SignUpFormTextFieldProps) => {
  const {
    name,
    setUserParams,
    isEmptyNationalityField,
    setIsEmptyNationalityField,
    mobileView,
  } = props;

  const currentCountry = process.env.NEXT_PUBLIC_JSW_GEOLOCATION;
  const currentCountryCheck = constants.nationalities;
  if (currentCountry != "my") {
    currentCountryCheck.splice(currentCountryCheck.indexOf("Singaporean"), 1);
    currentCountryCheck.sort();
    currentCountryCheck.splice(0, 0, "Singaporean");
  }

  const dispatch: typeof store.dispatch = useDispatch();
  const nationalities = currentCountryCheck;
  const stateRegions = useSelector((state: any) => state.jobs?.stateRegions);
  const options =
    name === "Nationality"
      ? nationalities
      : stateRegions?.map((stateRegion) => stateRegion.state);

  const validateNation = (param: any) => {
    if (param) {
      return true;
    }
    return false;
  };

  const validateLocation = (param: any) => {
    if (param) {
      return true;
    }
    return false;
  };

  const inputHandler = (e: any, value: any) => {
    if (name === "Nationality") {
      const isValid = validateNation(value);

      if (value !== "") {
        setIsEmptyNationalityField(false);
      }

      setUserParams((prev: any) => ({
        ...prev,
        nationality: value,
        validNationality: isValid,
      }));
    } else {
      const isValid = validateLocation(value);

      setUserParams((prev: any) => ({
        ...prev,
        currentLocation: value,
        validCurrentLocation: isValid,
      }));
    }
  };

  return (
    <AutocompleteStyled
      // size={mobileView ? "small" : "medium"}
      size="small"
      popupIcon={
        <ChevronDownIcon
          style={{ height: "1rem", width: "1rem", color: Color.darkGrey }}
        />
      }
      options={options}
      onChange={inputHandler}
      renderInput={(params) => (
        <TextFieldStyled
          {...params}
          label={name + "*"}
          error={isEmptyNationalityField}
          helperText={isEmptyNationalityField && "Required Field"}
        />
      )}
    />
  );
};

export default FormTextField;

import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as config from "../../../../config/config";
import { convertBase64 } from "../../../../helpers/data_management";
import useTriggerSnackbar from "../../../../hooks/useTriggerSnackbar";
import {
  getUser,
  newResumeUpload,
  updateFailedUploadResumePopupStatus,
  updatePreviewResumePoupupStatus,
  uploadResume,
} from "../../../../redux/actions/user_action";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { triggerSnackBar } from "../../../../helpers/snackbar_management";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import {
  hideSnackBar,
  showSnackBar,
} from "../../../../redux/actions/snackbar_action";
import UserResumeDialog from "../../../profile/ResumePopups/UserResumeDialog/UserResumeDialog";
import SharedToast from "../../../shared/SharedToast/SharedToast";
import {
  BoxContainer,
  ButtonContainer,
  DragResumeElement,
  EmptyContainer,
  ErrorContainer,
  ErrorOutlineIconStyled,
  FilenameText,
  ImportButton,
  InputStyled,
  InvalidResumeText,
  LinearProgressStyled,
  LottieStyled,
  OuterContainer,
  PercentageText,
  ResumeButton,
  ResumeRejectionList,
  ResumeStatusChip,
  SignUpResumeWrapper,
  SnackbarResumeCriteriaText,
  SupportFileText,
  UploadedResumeText,
  UploadFileOutlinedIconStyled,
} from "./styles";

const ResumeDropzone = forwardRef((props: any, ref: React.Ref<any>) => {
  const { complete, setComplete, resume, setResume } = props;
  const user = getUser();
  const resumeStatus = user?.state;

  const resumeStatusText =
    resumeStatus === "approved"
      ? "Approved"
      : resumeStatus === "rejected"
      ? "Rejected"
      : "In Review";
  // const [resume, setResume] = React.useState<string>(user?.resume ?? "");

  const existingResume = user?.resume;

  const [dragActive, setDragActive] = React.useState<boolean>(false);
  const [fileName, setFileName] = React.useState<string>("");
  const [validResume, setValidResume] = useState<boolean>(false);
  const [triggerLoading, setTriggerLoading] = React.useState<boolean>(false);

  const inputRef = React.useRef(null);
  const dispatch: any = useDispatch();
  const [progress, setProgress] = useState<any>(0);
  const [triggerSnackbarFunc] = useTriggerSnackbar();

  useEffect(() => {
    const timer = setInterval(() => {
      if (triggerLoading) {
        setProgress((oldProgress: number) => {
          if (oldProgress === 100) {
            setComplete(true);
          }

          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }
    }, 50);
    return () => {
      clearInterval(timer);
    };
  }, [triggerLoading]);

  const handleBrowseFiles = () => {
    inputRef.current.click();
  };

  function handleViewResume() {
    dispatch(
      updatePreviewResumePoupupStatus({ status: true, from: "preview" })
    );
  }

  function handleFindOutWhy() {
    dispatch(updateFailedUploadResumePopupStatus(true));
  }

  // Triggers when file is selected with click
  const handleFileChange = async function (e: any) {
    setValidResume(true);
    setFileName(e.target.files[0]?.name);

    const file = e.target.files[0];

    if (file?.name === undefined) {
      dispatch(uploadResume(""));
      setValidResume(false);
      setTriggerLoading(false);
      return;
    }

    if (parseFloat((file?.size / 1024 / 1024).toFixed(4)) > 2) {
      setValidResume(false);
      dispatch(uploadResume(""));
      setTriggerLoading(false);

      // Triggers error toast if file is larger than 2MB
      const cParams = {
        message: (
          <SnackbarResumeCriteriaText>
            Ensure that:
            <ResumeRejectionList>
              <li>file size is below 2MB</li>
              <li>resume is in the correct format</li>
            </ResumeRejectionList>
          </SnackbarResumeCriteriaText>
        ),
        severity: "error",
        isMoreThan2MB: true,
      };
      triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
      return;
    }

    setProgress(0);
    setComplete(false);
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFileName(e.target.files[0].name);
      setValidResume(true);
      setTriggerLoading(true);

      if (parseFloat((file.size / 1024 / 1024).toFixed(4)) > 2) {
        return false;
      }

      if (
        !(
          file.name?.includes(".docx") ||
          file.name?.includes(".doc") ||
          file.name?.includes(".pdf")
        )
      ) {
        return false;
      }

      // Converting PDF file to base64
      convertBase64(file).then((cvFile) => {
        // Trigger graphQL to upload cvFile
        let params = {
          resume: cvFile,
          canCancel: false,
          origin: "resume_onboarding",
          user_resume: user.resume,
          autoGenerated: false,
          state: "review",
        };

        // Upload new resume or update existing resume
        dispatch(newResumeUpload(params)).then((res) => {
          if (res.type === "UPDATE_USER_CV_SUCCEED") {
            sendTrackingEvent({
              event: "CE_resume_upload_first_time",
              origin: "onboarding",
              first_time: user?.uploadResumeAt ? false : true,
            });
            // Update local information
            setResume(res.user.resume);
            triggerSnackbarFunc({
              snackbarMessage: "Resume uploaded successfully",
              severity: "success",
            });
          } else {
            toast.error(
              res?.message ||
                "Your resume did not meet our criteria. Please reupload to proceed."
            );
          }
        });
      });
      // const isUploadSuccess = await useUploadResume(file, dispatch);
    }
  };

  // Triggers the input when the browse button is clicked
  const onBrowseClicked = () => {
    inputRef.current.click();
  };

  // Handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      if (!dragActive) {
        setDragActive(true);
      }
    } else if (e.type === "dragleave") {
      if (dragActive) {
        setDragActive(false);
      }
    }
  };

  // Triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (dragActive) {
      setDragActive(false);
    }
  };

  const getResumeName = (resume) => {
    if (resume) {
      const splitted = resume.split("/");
      const length = splitted.length;

      if (length > 1) {
        return splitted[length - 1];
      }
    }

    return "-";
  };

  return complete ? (
    <>
      <SharedToast />
      <SignUpResumeWrapper>
        <BoxContainer columnGap={{ sm: 0, md: "0.5rem" }}>
          <UploadedResumeText>
            {getResumeName(existingResume)}
          </UploadedResumeText>
          <ResumeStatusChip status={resumeStatus}>
            {resumeStatusText}
          </ResumeStatusChip>
        </BoxContainer>
        <ButtonContainer>
          {resumeStatus === "rejected" ? (
            <ResumeButton variant="normal" onClick={handleFindOutWhy}>
              Find Out Why
            </ResumeButton>
          ) : (
            <ResumeButton variant="normal" onClick={handleViewResume}>
              View Resume
            </ResumeButton>
          )}
          <ResumeButton variant="filled" onClick={handleBrowseFiles}>
            Reupload Resume
          </ResumeButton>
        </ButtonContainer>
        <InputStyled
          ref={inputRef}
          accept=".pdf,.docx,.doc"
          type="file"
          onChange={handleFileChange}
        />
      </SignUpResumeWrapper>
      <UserResumeDialog
        fromSignUp
        user={user}
        handleFileUpload={handleFileChange}
      />
    </>
  ) : (
    <>
      <SharedToast />
      <OuterContainer
        ref={ref}
        container
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        // maxWidth={{ xs: "100%", tablet: "80%" }}
        columnGap={{ sm: 0, md: "40px" }}
        rowGap={{ sm: "1rem", md: 0 }}
      >
        <EmptyContainer
          container
          justifyContent={{ xs: "center" }}
          sx={{ width: { xs: "100%", tablet: "20%" } }}
        >
          <LottieStyled
            play={true}
            path={`${config.assetDomain}/lottie/upload-file.json`}
          />
        </EmptyContainer>

        <EmptyContainer
          container
          justifyContent="center"
          alignContent="center"
          sx={{
            width: { xs: "100%", tablet: "50%" },
            padding: { xs: "0 1rem", tablet: 0 },
          }}
        >
          {!validResume && (
            <EmptyContainer
              container
              sx={{ flexDirection: "column", alignItems: "center" }}
            >
              <ImportButton onClick={onBrowseClicked}>
                Upload Resume
              </ImportButton>
              <SupportFileText>
                Support file type: .pdf, .doc, .docx (2MB Max)
              </SupportFileText>
            </EmptyContainer>
          )}

          {fileName && (
            <EmptyContainer
              container
              flexDirection="row"
              alignItems="center"
              justifyContent={{ xs: "center" }}
              sx={{ mt: "16px", height: "auto", gap: "6px" }}
            >
              {validResume && (
                <>
                  <UploadFileOutlinedIconStyled />

                  <EmptyContainer
                    container
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ width: { xs: "70%", tablet: "85%" } }}
                  >
                    <EmptyContainer container justifyContent="space-between">
                      <EmptyContainer
                        container
                        alignItems="center"
                        sx={{
                          maxWidth: "140px",
                          overflow: "hidden",
                          marginBottom: complete ? "" : "5px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <FilenameText>{`${fileName?.slice(
                          0,
                          16
                        )}...`}</FilenameText>
                      </EmptyContainer>

                      <PercentageText>{`${parseInt(
                        progress
                      )}%`}</PercentageText>
                    </EmptyContainer>

                    {!complete && validResume ? (
                      <LinearProgressStyled
                        variant="determinate"
                        value={progress}
                      />
                    ) : null}
                  </EmptyContainer>
                </>
              )}

              {!validResume && (
                <ErrorContainer>
                  <ErrorOutlineIconStyled />
                  <InvalidResumeText>
                    File is too large. Maximum file size is 2MB.
                  </InvalidResumeText>
                </ErrorContainer>
              )}
            </EmptyContainer>
          )}
          <InputStyled
            ref={inputRef}
            accept=".pdf,.docx,.doc"
            type="file"
            onChange={handleFileChange}
          />
        </EmptyContainer>

        {dragActive && (
          <DragResumeElement
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </OuterContainer>
    </>
  );
});

const mapDispatchToProps = (dispatch) => {
  return {
    hideSnackBar: bindActionCreators(hideSnackBar, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(ResumeDropzone);

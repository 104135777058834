import { useMediaQuery } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFailedUploadResumePopupStatus,
  updatePreviewResumePoupupStatus,
} from "../../../../redux/actions/user_action";
import ResumePdf from "../../ResumePdf/ResumePdf";
import {
  BoxContainer,
  ButtonStyled,
  CloseButton,
  CloseIcon,
  DangerIcon,
  DialogContainer,
  DialogContent,
  DialogFooter,
  DialogHeader,
  HeaderText,
  IframeStyled,
  InputStyled,
  IssueText,
  LinkStyled,
  ReasonContainer,
  ReasonItem,
  ReasonListContainer,
  ReasonTitleText,
  ResumeContainer,
  SubHeaderText,
} from "./styles";

export default function ResumeFailedDialog(props) {
  const { user, handleFileUpload, fromSignUp } = props;

  const isMobile = useMediaQuery("(max-width: 640px)");
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const resumePreviewDialog = useSelector(
    (state) => state.user.isPreviewResumeOpen
  );

  const resumeRejectedDialog = useSelector(
    (state) => state.user.isFailedUploadResumeOpen
  );

  const isHiredlyResumeRejected = useSelector(
    (state) => state.user.isHiredlyResumeRejected
  );

  const openDialog = resumePreviewDialog || resumeRejectedDialog;

  const signUpResumeFailedReasons = [
    <>
      Contains your <b>name, phone number</b> and <b>email</b>.
    </>,
    <>
      A <b>clear digital resume</b>.
    </>,
    <>Contains work experience and/or education details.</>,
    <>
      Resume written in <b>English</b> only.
    </>,
    <>
      An original resume that is <b>not copyrighted</b> by other job platforms.
    </>,
    <>No negative sentiments.</>,
  ];

  const reasons = [
    <>
      Full <b>contact details</b> listed in your resume - Email, mobile number
      and current location (e.g. Petaling Jaya).
    </>,
    <>
      A <b>clear digital resume</b>.
    </>,
    <>
      Resume written in <b>English</b> only.
    </>,
    <>
      An original resume that is <b>not copyrighted</b> by other job platforms.
    </>,
    <>
      A resume uploaded in <b>Microsoft Word Doc</b> or <b>PDF form</b>{" "}
      (preferred).
    </>,
  ];

  const resumePreviewText = isMobile
    ? "Make sure to review your details before proceeding."
    : "Review your details before proceeding.";

  const resumeUrl = user?.resume?.includes("pdf")
    ? user.resume
    : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURI(
        user.resume
      )}`;

  function handleClose() {
    dispatch(updateFailedUploadResumePopupStatus(false));
    dispatch(
      // reason for adding "from" is so that when closing preview popup, resume rearrange component will not flash for a second
      updatePreviewResumePoupupStatus({
        status: false,
        from: props.isPreviewResume ? "preview" : "",
      })
    );
  }

  const handleFileChange = function (e) {
    e.preventDefault();
    if (handleFileUpload) {
      handleFileUpload(e);
    }
    handleClose();
  };

  function handleUploadResume() {
    if (!user?.autoGeneratedResume && !isHiredlyResumeRejected) {
      inputRef?.current?.click();
    } else {
      handleClose();
    }
  }

  return (
    <DialogContainer
      open={openDialog}
      onClose={handleClose}
      resumePreview={resumePreviewDialog}
    >
      <DialogHeader>
        <BoxContainer alignItems={"center"} justifyContent={"space-between"}>
          {resumeRejectedDialog ? (
            <>
              <BoxContainer alignItems={"center"} gap={"0.5rem"}>
                <DangerIcon />
                <HeaderText>Your Resume was Rejected</HeaderText>
              </BoxContainer>

              <CloseButton onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
            </>
          ) : (
            <BoxContainer
              flexDirection={"column"}
              gap={"0.25rem"}
              width={"100%"}
            >
              <BoxContainer
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <HeaderText>Resume Preview</HeaderText>{" "}
                <CloseButton onClick={handleClose}>
                  <CloseIcon />
                </CloseButton>
              </BoxContainer>
              {!fromSignUp && (
                <SubHeaderText>{resumePreviewText}</SubHeaderText>
              )}
            </BoxContainer>
          )}
        </BoxContainer>
      </DialogHeader>

      <DialogContent fullWidth={resumePreviewDialog}>
        {resumeRejectedDialog && (
          <ReasonContainer fromSignUp>
            <BoxContainer flexDirection={"column"} fromSignUp gap={"1rem"}>
              {fromSignUp ? (
                <ReasonTitleText>
                  Your resume didn't meet all the criteria. Please review the
                  criteria below to see what needs to be updated.
                </ReasonTitleText>
              ) : (
                <ReasonTitleText>
                  Your resume is not meeting our criteria. Please check whether
                  your resume fulfils the criteria below:
                </ReasonTitleText>
              )}

              <ReasonListContainer fromSignUp>
                {fromSignUp
                  ? signUpResumeFailedReasons.map((reason, index) => (
                      <ReasonItem key={index}>{reason}</ReasonItem>
                    ))
                  : reasons.map((reason, index) => (
                      <ReasonItem key={index}>{reason}</ReasonItem>
                    ))}
              </ReasonListContainer>
            </BoxContainer>
            <IssueText>
              Having issue on your resume? <br />{" "}
              <LinkStyled href={"mailto:hello@hiredly.com"}>
                Reach out
              </LinkStyled>{" "}
              to our freindly customer service for help!
            </IssueText>
          </ReasonContainer>
        )}

        <ResumeContainer>
          {(user && user.autoGeneratedResume) || isHiredlyResumeRejected ? (
            <PDFViewer
              showToolbar={false}
              width={"100%"}
              style={{ height: "100%" }}
            >
              <ResumePdf currentUser={user} />
            </PDFViewer>
          ) : (
            user && (
              <IframeStyled
                key={user.resume}
                src={`${resumeUrl}#toolbar=0`} // adding the toolbar=0 will hide the toolbar at the side of pdf viewer
              />
            )
          )}
        </ResumeContainer>
      </DialogContent>

      <DialogFooter>
        <InputStyled
          ref={inputRef}
          accept=".pdf,.docx,.doc"
          type="file"
          onChange={handleFileChange}
        />
        <ButtonStyled
          onClick={handleUploadResume}
          filled={resumeRejectedDialog}
        >
          Reupload Resume
        </ButtonStyled>
      </DialogFooter>
    </DialogContainer>
  );
}

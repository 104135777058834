import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import PhoneInput from "react-phone-number-input";
import Color from "../../../../assets/colors";

export const PhoneInputStyled = styling(PhoneInput)((props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  height: "40px",
  padding: "8px 16px",
  border: props.valid
    ? `1px solid ${Color.secondaryGrey}`
    : `1px solid #C62828`,
  marginBottom: props.valid ? "0.75rem" : "0rem",

  ":focus": {
    border: props.valid
      ? `1px solid ${Color.hiredlyPurple}`
      : `1px solid #D32F2F`,
  },

  ":hover": {
    border: props.valid ? `1px solid black` : `1px solid #D32F2F`,
  },

  [props.theme.breakpoints.up("tablet")]: {
    "& .PhoneInputInput": {
      fontSize: "16px",
      border: "none",
    },
  },

  [props.theme.breakpoints.down("tablet")]: {
    "& .PhoneInputInput": {
      fontSize: "14px",
      border: "none",
      width: "50%",
    },
  },

  [props.theme.breakpoints.up("md")]: {
    "& .PhoneInputInput": {
      flex: 1,
    },
  },

  [props.theme.breakpoints.down("md")]: {
    "& .PhoneInputInput": {
      flex: 1,
      border: "none",
    },
  },

  [props.theme.breakpoints.down(768)]: {
    marginBottom: props.valid ? "0.5rem" : "0rem",
  },

  [props.theme.breakpoints.down("sm")]: {
    height: "37.13px",
  },

  "& .PhoneInputInput:focus": {
    outline: "none",
  },

  "& .PhoneInputCountryIcon": {
    width: "calc(1em * 1.5)",
    height: "1em",
  },

  "& .PhoneInputCountryIcon--square": {
    width: "1em",
  },

  "& .PhoneInputCountryIconImg": {
    display: "block",
    width: "100%",
    height: "100%",
  },

  "& .PhoneInputInternationalIconPhone": {
    opacity: "0.8",
  },

  "& .PhoneInputInternationalIconGlobe": {
    opacity: "0.65",
  },

  "& .PhoneInputCountry": {
    position: "relative",
    alignSelf: "stretch",
    display: "flex",
    alignItems: "center",
    marginRight: "0.35em",
  },

  "& .PhoneInputCountrySelect": {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
    border: 0,
    opacity: 0,
    cursor: "pointer",
  },

  "& .PhoneInputCountrySelectArrow": {
    display: "block",
    width: "0.3em",
    height: "0.3em",
    marginLeft: "0.35em",
    borderStyle: "solid",
    borderColor: "inherit",
    borderTopWidth: 0,
    borderBottomWidth: "1px",
    borderLeftWidth: 0,
    borderRightWidth: "1px",
    transform: "rotate(45deg)",
    opacity: "0.45",
  },
}));

export const ContainerStyled = styling(Container)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
});

export const TextFieldStyled = styling(TextField)((props) => ({
  width: "100%",
  // height: "48px",
  marginBottom: "0.75rem",
  backgroundColor: Color.white,
  border: "1px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "1px",
    // padding: "0.5rem 1rem",

    // "& .MuiInputBase-input": {
    //   height: "32px",
    // },

    "&.Mui-focused fieldset": {
      border: props.error
        ? "1px solid #C62828"
        : `1px solid ${Color.hiredlyPurple}`, // Change border color when focused
    },
  },

  [props.theme.breakpoints.down(768)]: {
    marginBottom: "0.5rem",
  },

  [props.theme.breakpoints.down("sm")]: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      "&.Mui-focused fieldset": {
        border: props.error
          ? "1px solid #C62828"
          : `1px solid ${Color.hiredlyPurple}`, // Change border color when focused
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px",
    },
  },
}));

export const AutocompleteStyled = styling(Autocomplete)({});

export const PhoneInputContainer = styling(Box)({
  display: "flex",
  flexDirection: "column",
  marginBottom: "0.75rem",
});

export const ErrorText = styling(Typography)((props) => ({
  fontSize: "0.75rem",
  color: "#d32f2f",
  // position: "relative",
  left: "15px",
  bottom: "4px",
  marginLeft: "0.875rem",
  marginBottom: "0.875rem",
  marginTop: "4px",
  lineHeight: "1.66",

  [props.theme.breakpoints.down(768)]: {
    marginBottom: "0.5rem",
  },
}));
